import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { FaPaintBrush } from 'react-icons/fa';

function Navbar() {
    return (
        <nav className="navbar">
            <div className="nav-container">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <div className="buttons">
                    <div className='button'>
                        <Link to="/settings">
                            <FaPaintBrush />
                        </Link>
                    </div>
                    <div className="logout button" onClick={() => {
                        localStorage.removeItem('authJWT')
                        window.location.href = 'https://tictactoe-bot.xyz'  
                    }}>
                        <FiLogOut />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
