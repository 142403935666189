module.exports = [
    {
        "id": "dev",
        "name": "Devloper",
        "icon": "/img/badges/dev.svg"
    },
    {
        "id": "staff",
        "name": "Staff",
        "icon": "/img/badges/staff.svg"
    },
    {
        "id": "verified",
        "name": "Verified",
        "icon": "/img/badges/verified.svg"
    },
    {
        "id": "bug_reporter",
        "name": "Bug Reporter",
        "icon": "/img/badges/bug_reporter.svg"
    }
]