import { useEffect } from "react"

function Login() {
    useEffect(() => {
        window.location.href = process.env.REACT_APP_API_URL + '/dash/auth'
    })  

    return (
        <>
            <p>You will be redirected to Discord OAuth Service!</p>
        </>
    )
}

export default Login
