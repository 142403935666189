import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { RiSwordFill, RiTrophyFill, RiScalesFill, RiRepeatFill, RiGamepadFill, RiCoinsFill } from "react-icons/ri"
import { FaRegCircle } from "react-icons/fa"
import { ImCross } from "react-icons/im"
import Navbar from "../components/Navbar"
import DefaultAvatar from '../assets/default_avatar.png'
import Level from '../components/LevelProgress'
import Badge from '../components/Badge'
import Mention from "../components/Mention"
import dcIcon from '../assets/dc_icon.svg'
import CustomizeBanner from '../assets/customize_banner.png'

const Profile = (props) => {

    const { userid } = useParams()
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [gamePage, setGamePage] = useState(1)

    useEffect(() => {
        const url = props.me ? 'me' : userid
        fetch(`${process.env.REACT_APP_API_URL}/dash/user/@${url}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authJWT")
            }
        })
            .then(res => {res.status === 404 && setError("User not found"); return res})
            .then(res => {res.status === 500 && setError("Error fetching user"); return res})
            .then(res => res.json())
            .then(d => {
                if (d.user.info === undefined || d.user.info === null) {
                    d.user.info = {
                        username: d.user.id,
                        avatar: DefaultAvatar,
                        discriminator: '',
                    }
                } else {
                    d.user.info.discriminator = '#' + d.user.info.discriminator 
                    if (d.user.info.avatar === undefined || d.user.info.avatar === null) {
                        d.user.info.avatar = DefaultAvatar
                    } else {
                        d.user.info.avatar = `https://cdn.discordapp.com/avatars/${d.user.id}/${d.user.info.avatar}?size=256` 
                    }
                }
                setData(d)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [userid])

    function loadMoreGames() {
        console.log(gamePage)
        fetch(`${process.env.REACT_APP_API_URL}/dash/user/${props.me ? data.user.id : userid}/games/${gamePage}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authJWT")
            }
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === 404) {
                setError("User not found")
            } else if (res.status === 500) {
                setError("Error fetching user")
            } else {
                setData({...data, games: data.games.concat(res.games)})
                setGamePage(gamePage + 1)
            }
        })
    }

    return (
        <div className='profile'>
            <div className="container">
                <Navbar />
                {error !== null && <div className="error">{error}</div>}
                {data !== null &&
                    <>
                        <div className="userbar">
                            <div className="float">
                                <div className="avatar">
                                    <img src={data.user.info.avatar} alt="avatar" />
                                </div>
                                <div className="info">
                                    <div className="row">
                                        <h1 className="name">
                                            {data.user.info.username}
                                            <span className="tag">{data.user.info.discriminator}</span>
                                        </h1>
                                        <div className="badges">
                                            {data.user.info.badges && data.user.info.badges.map((badge, i) => 
                                                <Badge key={i} badge={badge} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Level wins={data.user.stats.wins} /> 
                                    </div>
                                </div>
                            </div>
                            <div className="float">
                                <a className="addButton" target='_blank' href={`https://discord.com/users/${data.user.id}`}>
                                    <img src={dcIcon} /> <span>Friend</span> 
                                </a>
                            </div>
                        </div>
                        <div className="stats">
                            <div className="item">
                                <div className="icon"><RiTrophyFill /></div>
                                <div className="value">{data.user.stats.wins}</div>
                                <div className="label">Wins</div>
                            </div>
                            <div className="item">
                                <div className="icon"><RiSwordFill /></div>
                                <div className="value">{data.user.stats.loses}</div>
                                <div className="label">Loses</div>
                            </div>
                            <div className="item">
                                <div className="icon"><RiScalesFill /></div>
                                <div className="value">{data.user.stats.draws}</div>
                                <div className="label">Draws</div>
                            </div>
                            <div className="item">
                                <div className="icon"><RiRepeatFill /></div>
                                <div className="value">{data.user.stats.turns}</div>
                                <div className="label">Turns</div>
                            </div>
                            <div className="item">
                                <div className="icon"><RiGamepadFill /></div>
                                <div className="value">{data.user.stats.loses + data.user.stats.wins + data.user.stats.draws}</div>
                                <div className="label">Games</div>
                            </div>
                            <div className="item">
                                <div className="icon"><RiCoinsFill /></div>
                                <div className="value">Soon</div>
                                <div className="label">Premium</div>
                            </div>
                        </div>
                        {props.me && 
                            <Link to="/settings">
                                <img className="customize_banner" src={CustomizeBanner} alt="TTT Customize Banner" />
                            </Link>
                        }
                        <h2>Games:</h2>
                        <div className="games">
                            {data.games.map((game, i) =>
                                <div key={i} className="board">
                                    <img src={`${process.env.REACT_APP_API_URL}/board?f1=${game.board[0]}&f2=${game.board[1]}&f3=${game.board[2]}&f4=${game.board[3]}&f5=${game.board[4]}&f6=${game.board[5]}&f7=${game.board[6]}&f8=${game.board[7]}&f9=${game.board[8]}&cId=${game.gameId}&detectWin=true`} alt="Game Board" />
                                    <div className="stats">
                                        <Link className="stat" to={`/@${game.playerX}`}>
                                            <div className="icon"><ImCross /></div>
                                            <div className="value"><Mention userid={game.playerX} /></div>
                                        </Link> 
                                        <Link className="stat" to={`/@${game.playerO}`}>
                                            <div className="icon"><FaRegCircle /></div>
                                            <div className="value"><Mention userid={game.playerO} /></div>
                                        </Link> 
                                    </div>
                                </div>
                            )}
                            <div className="btn-container">
                                {data.games.length > 5 && <button className="loadMore" onClick={loadMoreGames}>More</button>}
                            </div>
                        </div>
                    </>
                }
            </div>
            
        </div>
    )
}

Profile.defaultProps = {
    me: false
}

export default Profile
