import Badges from '../utils/BadgesData'

function Badge({ badge }) {


    return (
        <div className="badge">
            <img src={Badges.filter(b => b.id === badge)[0].icon} />
            <div className="popover">
                <p className="name">{Badges.filter(b => b.id === badge)[0].name}</p>
            </div>
        </div>
    )
}

export default Badge
