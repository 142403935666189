import Navbar from "../components/Navbar"
import { useParams, Link } from "react-router-dom";
import Panel from "./settings/Panel";
import Soon from "./settings/Soon";
import Disabled from "./settings/Disabled";

function Settings() {
    const { category } = useParams()

    async function resetPrefs() {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/dash/settings/reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authJWT")
            }
        })
    }

    return (
        <div className='settings'>
            <div className="container">
                <Navbar />
                <div className="content">
                    <div className="panel">
                        <Disabled />
                    </div>
                </div>
            </div>
        </div>
    )

    // return (
    //     <div className='settings'>
    //         <div className="container">
    //             <Navbar />
    //             <div className="content">
    //                 <ul className={`categories ${category === undefined && 'mobile-categories'}`}>
    //                     <Link to="/settings/icon_color"><li className={(category === 'icon_color' && 'active') || (category === undefined && 'active mobileNotActive')}>Symbol Color</li></Link>
    //                     <Link to="/settings/grid_color"><li className={category === 'grid_color' && 'active'}>Grid Color</li></Link>
    //                     <Link to="/settings/bg_color"><li className={category === 'bg_color' && 'active'}>Background Color</li></Link>
    //                     <Link to="/settings/bg_image"><li className={category === 'bg_image' && 'active'}>Background Image</li></Link>
    //                     <Link to="/settings/emoji"><li className={category === 'emoji' && 'active'}>Emoji</li></Link>
    //                     <Link to="/settings/winner_anim"><li className={category === 'winner_anim' && 'active'}>Winner Animation</li></Link>
    //                     <Link to="/settings/loser_dm"><li className={category === 'loser_dm' && 'active'}>Loser DM</li></Link>
    //                     <Link to="/settings/leaderboard"><li className={category === 'leaderboard' && 'active'}>Leaderboard</li></Link>
    //                     <li onClick={() => resetPrefs()} className="danger">Reset</li>
    //                 </ul>
    //                 <div className="panel">
    //                     {(category === 'icon_color' && <Panel title="Symbol Color" url="icon_color" />) || (category === undefined && <div className="pc-only"><Panel title="Symbol Color" url="icon_color" /></div>)}
    //                     {category === 'grid_color' && <Panel title="Grid Color" url="grid_color" />}
    //                     {category === 'bg_color' && <Panel title="Background Color" url="bg_color" />}
    //                     {category === 'bg_image' && <Soon />}
    //                     {category === 'emoji' && <Soon />}
    //                     {category === 'winner_anim' && <Soon />}
    //                     {category === 'loser_dm' && <Soon />}
    //                     {category === 'leaderboard' && <Soon />}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default Settings
