import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Profile from './views/Profile.js';
import NotFound from './views/NotFound.js';
import Login from './views/Login.js';
import LoginCallback from './views/LoginCallback.js';
import Settings from './views/Settings.js';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(async () => {
    if (window.location.pathname === '/login') return
    if (await isLoggedIn()) {
      setLoggedIn(true)
    } else {
      window.location.href = '/login'
      console.log('not logged in') 
    }
  }, [window.location.href])

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const isLoggedIn = async () => {
    const token = localStorage.getItem('authJWT')
    if (token) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dash/auth/check`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      })
      .catch(err => {
        console.log(err)
      })
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } else return false
  }

  return (
    <div className="App">
      <Routes>
        {loggedIn &&
          <>
            <Route path="/" element={<Profile me={true} />} />
            <Route path="/@:userid" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/:category" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </>
        }
        <Route path="/callback" element={<LoginCallback />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
