import { useEffect } from "react"

function LoginCallback() {
    
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const token = params.token;
        localStorage.setItem('authJWT', token);
        window.location.href = '/';
    })

    return (
        <></>
    )
}

export default LoginCallback
