import Navbar from "../components/Navbar"
import Graphic from '../assets/404.png'

function Home() {
    return (
        <div className="notFound">
            <div className="container">
                <Navbar />
                <div className="content">
                    <img src={Graphic} alt="TTT 404" />
                    <div>
                        <h1>Oh Oh!</h1>
                        <h2>404 - This page was not found</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
