const LevelProgress = ({wins}) => {
    const level = (wins) => {
        if (wins === 0) return 0
        let level = 0
        if (wins < 9) {
            level = Math.log(Math.pow(wins, 10))
        } else {
            level = (10 / 9) * wins + 11.9
        }

        level = Math.floor(level / 10) + 1
        return level
    }

    const progress = () => {
        if (wins === 0) return 0
        const currentLevel = level(wins)
        const nextLevel = () => {
            let found = false
            let index = 0
            let count = 0
            while (!found) {
                let newLevel = level(wins + index)
                if (newLevel === currentLevel + 1) {
                    found = true
                    count = index
                }
                index++
            }
            return count
        }

        const lastLevel = () => {
            let found = false
            let index = 0
            let count = 0
            while (!found) {
                let newLevel = level(wins - index)
                if (newLevel === currentLevel - 1) {
                    found = true
                    count = index
                }
                index++
            }
            return count
        }
       
        const ges = lastLevel() + nextLevel()
        const percent = Math.floor((lastLevel() / ges) * 100)
        return percent        
    }

    return (
        <div className="levelProgress">
            <div className="level"><p>{level(wins)}</p></div>
            <div className="progress">
                <div className="bar" style={{ width: `${progress()}%` }}></div>
            </div>
        </div>
    )
}

LevelProgress.defaultProps = {
    wins: 3
}

export default LevelProgress


