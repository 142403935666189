import { Link } from 'react-router-dom'
import { BiArrowBack } from "react-icons/bi";
import Graphic from '../../assets/disabled.svg'

function Soon() {

    return (
        <>
            <Link to="/"><h1 className="title" style={{textAlign: 'center'}}><BiArrowBack className="backIcon" />Disabled</h1></Link>
            <div className="pane soon">
                <img className='graphic' src={Graphic} alt="Disabled" />
                <p className='desc'>
                In order to keep the bot running well and stable due to its rapidly growing size, we unfortunately had to temporarily disable the game board customization feature. Currently, there is no ETA on when it will be enabled again.
                </p>
                <a href="https://discord.gg/BrDJ9RS6TX" target="_blank" className="support">Join our Support Server</a>
            </div>
        </>
    )
}

export default Soon