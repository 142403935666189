import { useEffect, useState } from "react"

function Mention(props) {

    const [username, setUsername] = useState('')

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/dash/username/${props.userid}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authJWT')
                }
            }
            )
            .then(res => res.json())
            .then(response => {
                setUsername(response.username)
            })
            .catch(err => {
                console.log(err)
                setUsername('Uneeknown')
            })
    }, [props.userid])


    return (
        <span className="mention">@{username}</span>
    )
}

export default Mention