import { useEffect, useState } from 'react';

function Preview({ game }) {

    const [reloadParam, setReloadParam] = useState(new Date().getTime());

    useEffect(() => {
        setInterval(() => {
            setReloadParam(new Date().getTime());
        }, 1000);
    }, [])

    return <div className='preview'>
        <img src={`${process.env.REACT_APP_API_URL}/board?cId=${game}&rp=${reloadParam}`} alt="Preview Board" />
    </div>;
}

export default Preview;
